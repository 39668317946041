import React from "react";

export default function NavSubItem({ item, path, openPath }) {
    const handleClick = (e) => {
        e.preventDefault();
        if (item.hasChildren) {
            openPath([...item.parents, item]);
        } else if (item.url) {
            window.location = item.url;
        }
    };

    return (
        <li className={item.hasChildren ? "has-children" : ""}>
            <a href={item.url} onClick={handleClick}>
                {item.name}
            </a>
        </li>
    );
};
