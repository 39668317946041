import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker, InfoWindow, MarkerClusterer } from "@react-google-maps/api";
import MapControl from "./MapControl.jsx";

const defaultCenter = { lat: 51.588553, lng: 10.118349 };
const fegPinPath = "M49.9900116,99.9840235 C47.7014482,88.7495959 43.6663803,79.400169 38.7790227,70.7355509 C35.1538408,64.308374 30.9542438,58.3759133 27.0685371,52.1429644 C25.771412,50.0623831 24.651985,47.8643185 23.4055719,45.7049846 C20.9133354,41.3873157 18.8926743,36.3812381 19.0210756,29.8875272 C19.1465257,23.5428209 20.9815212,18.4533251 23.6276026,14.2920421 C27.9796289,7.44777988 35.269432,1.83623523 45.0505837,0.361531834 C53.0478651,-0.844205367 60.5458829,1.1928675 65.8630815,4.30202273 C70.207847,6.8427863 73.5728614,10.2366684 76.1302688,14.236491 C78.7998451,18.4112341 80.6382068,23.3432814 80.7922878,29.7764793 C80.8714005,33.0724236 80.3318169,36.1246699 79.5714411,38.6564589 C78.8022089,41.219124 77.5648305,43.361281 76.4638202,45.64943 C74.3143411,50.1159848 71.6199676,54.2086104 68.9155622,58.3033004 C60.8609568,70.5005915 53.300949,82.9393418 49.9900116,99.9840235 L49.9900116,99.9840235 Z M49.9077163,40.6387476 C55.8851638,40.6387476 60.7308424,35.793069 60.7308424,29.8156216 C60.7308424,23.8381741 55.8851638,18.9924955 49.9077163,18.9924955 C43.9302688,18.9924955 39.0845903,23.8381741 39.0845903,29.8156216 C39.0845903,35.793069 43.9302688,40.6387476 49.9077163,40.6387476 Z";

const styles = [
    { "featureType": "all", "elementType": "geometry", "stylers": [{ "saturation": "-100" }, { "lightness": "30" }] },
    { "featureType": "all", "elementType": "labels", "stylers": [{ "saturation": "-100" }, { "lightness": "10" }] }
];

export default function Map({ locations = [], l10n, searchquery, searchbar, zoom, pincolor }) {
    const [map, setMap] = useState(null);
    const [center, setCenter] = useState(null); // Initially null, no default center
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [searchQuery, setSearchQuery] = useState(searchquery);
    const [persistentSearchedLocation, setPersistentSearchedLocation] = useState(null); // No red marker initially
    const [isLoaded, setIsLoaded] = useState(false);
    const [showSearchbar, setShowSearchbar] = useState(searchbar === "true" || searchbar === "1");
    const prevSearchQueryRef = useRef(searchquery);

    const mapZoom = parseInt(zoom, 10) || 6; // Initial zoom, adjusted by fitBounds
    const safeLocations = Array.isArray(locations) ? locations : [];

    useEffect(() => {
        const checkGoogleAPI = () => {
            if (typeof google !== 'undefined') {
                setIsLoaded(true);
                if (searchquery) {
                    if (searchquery.includes(',')) {
                        const [lat, lng] = searchquery.split(',').map(Number);
                        if (!isNaN(lat) && !isNaN(lng)) {
                            setCenter({ lat, lng });
                            setPersistentSearchedLocation({ lat, lng }); // Sets red marker
                        } else {
                            console.error("Invalid coordinates in searchquery:", searchquery);
                        }
                    } else {
                        fetchCoordinatesFromAddress(searchquery);
                    }
                    prevSearchQueryRef.current = searchquery;
                }
            } else {
                setTimeout(checkGoogleAPI, 1000);
            }
        };
    
        checkGoogleAPI();
        window.addEventListener('google-api-loaded', checkGoogleAPI);
    
        return () => {
            window.removeEventListener('google-api-loaded', checkGoogleAPI);
        };
    }, [searchquery]);

    const handleSelect = (location) => {
        if (!isLoaded) return;
        setSelectedLocation(location);
        setCenter({ lat: parseFloat(location.location.lat), lng: parseFloat(location.location.lng) });
    };

    const handleLocate = (coords, query) => {
        if (!isLoaded) return;
        try {
            const [lat, lng] = coords;
            if (Number.isFinite(lat) && Number.isFinite(lng)) {
                setCenter({ lat, lng });
                setSearchQuery(query);
                setPersistentSearchedLocation({ lat, lng }); // Red marker appears here
                map.setZoom(15);
            } else {
                console.error("Invalid coordinates in handleLocate:", coords);
            }
        } catch (error) {
            console.error("Error locating coordinates:", error);
        }
    };

    const handleLoad = (mapInstance) => {
        if (!isLoaded) return;
        setMap(mapInstance);
        // Fit bounds to show all locations initially
        if (safeLocations.length > 0) {
            const bounds = new google.maps.LatLngBounds();
            safeLocations.forEach(location => {
                bounds.extend({
                    lat: parseFloat(location.location.lat),
                    lng: parseFloat(location.location.lng),
                });
            });
            mapInstance.fitBounds(bounds);
        }
    };

    const handleUnmount = () => {
        if (!isLoaded) return;
        setMap(null);
    };

    if (!isLoaded || !safeLocations.length) {
        return <div>{l10n?.no_data || "No locations or search query provided."}</div>;
    }

    return (
        <div className="googlemap">
            {map && showSearchbar && (
                <MapControl
                    map={map}
                    controlPosition={google.maps.ControlPosition.LEFT}
                    locations={safeLocations}
                    l10n={l10n}
                    query={searchQuery}
                    setQuery={setSearchQuery}
                    onLocate={handleLocate}
                    onSelect={handleSelect}
                    position={persistentSearchedLocation ? [persistentSearchedLocation.lat, persistentSearchedLocation.lng] : null} // Null until search
                    searchbar={showSearchbar}
                />
            )}
            <GoogleMap
                center={center} // Null initially, uses fitBounds
                zoom={mapZoom}
                onLoad={handleLoad}
                onUnmount={handleUnmount}
                options={{
                    styles: styles,
                    mapTypeControlOptions: { mapTypeIds: [] },
                    streetViewControl: false,
                }}
            >
                {safeLocations.length > 0 && (
                    <MarkerClusterer>
                        {(clusterer) =>
                            safeLocations.map((location, index) => (
                                <Marker
                                    key={index}
                                    position={{
                                        lat: parseFloat(location.location.lat),
                                        lng: parseFloat(location.location.lng),
                                    }}
                                    onClick={() => handleSelect(location)}
                                    icon={{
                                        path: fegPinPath,
                                        fillColor: pincolor || "#00a06e",
                                        fillOpacity: 1,
                                        scale: 0.5,
                                        anchor: new google.maps.Point(50, 100),
                                        strokeWeight: 1,
                                        strokeColor: "#fff",
                                    }}
                                    clusterer={clusterer}
                                />
                            ))
                        }
                    </MarkerClusterer>
                )}

                {persistentSearchedLocation && (
                    <Marker
                        position={persistentSearchedLocation}
                        icon={{
                            path: fegPinPath,
                            fillColor: "red",
                            fillOpacity: 1,
                            scale: 0.5,
                            anchor: new google.maps.Point(50, 100),
                            strokeWeight: 1,
                            strokeColor: "#fff",
                        }}
                    />
                )}

                {selectedLocation && (
                    <InfoWindow
                        position={{
                            lat: parseFloat(selectedLocation.location.lat),
                            lng: parseFloat(selectedLocation.location.lng),
                        }}
                        onCloseClick={() => setSelectedLocation(null)}
                    >
                        <div>
                            <h6>{selectedLocation.name}</h6>
                            <p>{selectedLocation.location.address}</p>
                            {selectedLocation.contactperson && <p>{selectedLocation.contactperson}</p>}
                            {selectedLocation.email && (
                                <p><a href={`mailto:${selectedLocation.email}`}>{selectedLocation.email}</a></p>
                            )}
                            {selectedLocation.phone && (
                                <p><a href={`tel:${selectedLocation.phone}`}>{selectedLocation.phone}</a></p>
                            )}
                            {selectedLocation.website && (
                                <span>
                                    <a href={selectedLocation.website} target="_blank" rel="noopener noreferrer">
                                        {l10n.website + " | "}
                                    </a>
                                </span>
                            )}
                            {selectedLocation.url && (
                                <a href={selectedLocation.url} target="_blank" rel="noopener noreferrer">
                                    {l10n.visit}
                                </a>
                            )}
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>
        </div>
    );
}